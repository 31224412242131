import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import PlaceAutocomplete from './PlaceAutocomplete';
import {displayCO2, transportTypes, modesAllowingPassengers} from '../calculations';
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
const numeral = require('numeral');

export default function Journey({ updateJourneyField, journey, calculateCo2, removeJourney, index }) {
	const result = calculateCo2(journey);
	const numPassengers = [1,2,3,4,5,6];
	return <Row className="journey">
        <Col className="col-delete">
            <Button variant="light" onClick={removeJourney}><FontAwesomeIcon icon={faTrash} /></Button>
        </Col>
		<Col className="col-origin">
			<PlaceAutocomplete onChange={updateJourneyField('origin')} key='origin' id={'origin-autocomplete-' + index} selected={journey.origin && journey.origin.lat ? [journey.origin] : null} />
		</Col>
		<Col className="col-destination">
			<PlaceAutocomplete onChange={updateJourneyField('destination')} key='destination' id={'destination-autocomplete-' + index} />
		</Col>
		<Col className="col-return text-center">
			<Form.Check 
				type='checkbox'
				onChange={e => {
					updateJourneyField('isReturn')(!!e.target.checked)
				}}
			/>        
		</Col>
		<Col className="col-mode">
			<Form.Control 
				as="select"
				onChange={e => {
					updateJourneyField('type')(e.target.value)
				}}
			>
				{transportTypes.map(type => <option key={type.key} value={type.key}>{type.value}</option>)}
				
			</Form.Control>        
		</Col>
		<Col className="col-mode">
			<Form.Control 
				as="select"
				onChange={e => {
					updateJourneyField('passengers')(e.target.value)
				}}
				disabled={!modesAllowingPassengers.includes(journey.type)}
			>
				{numPassengers.map(num => <option key={num} value={num}>{num}</option>)}
				
			</Form.Control>        
		</Col>
		<Col className="col-distance col-text">
			<span>
      	{numeral(result[0]).format('0,0') + ' km'}
			</span>
		</Col>
    <Col className="col-co2 col-text">
      <span>
        {displayCO2(result[1])}
      </span>
		</Col>
	</Row>
}