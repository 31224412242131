import React from 'react';

export default function Calculations() {
  return <>
      <p>This carbon calculator allows you to evaluate the carbon footprint of your travel. By taking into account factors including distance travelled, mode of transport used and number of passengers travelling, it is possible to calculate the levels of greenhouse gasses that your journey produces. Our calculator uses values for calculating greenhouse gas emission based around the DEFRA's Guidelines for GHG conversion factors, these are available to download <a rel="noopener noreferrer" target="_blank" href="http://www.ukconversionfactorscarbonsmart.co.uk">here</a>.</p> <p>The values are expressed in masses of CO<span className='co2sub'>2</span> equivalent.  CO<span className='co2sub'>2</span> is the primary greenhouse gas associated with travel but we have taken into account other gasses and converting them to CO<span className='co2sub'>2</span> masses based on their global warming potential.</p>

      <p>Explained in more depth below are the principles upon which The Converging World's travel calculator calculates your journey's emissions.</p>

      <h3>Distance</h3>
      <p>Distances are calculated using the spherical law of cosines:</p>
      <p>d = acos(sin(lat1) x sin(lat2) + cos(lat1) x cos(lat2) x cos(long2-long1)) x R</p>
      <p>Where R is the radius of the earth (rounded to 6371 km) and lat1, long1 are the latitude and longitude of the journey origin converted from degrees to radians and lat2, long2 are those of the destination.  It assumes a straight line journey for simplicity which is reasonably accurate for flights but may not be for overland journeys.  It may be more accurate to calculate the distance by inputting a number of sub-journeys (i.e. breaking the journey down into major way-points).</p>

      <h3>Carbon</h3>
      <p>The calculator uses the following values for calculating greehouse gas emissions.  These are based around DEFRA's Guidelines for GHG conversion factors available to download <a rel="noopener noreferrer" target="_blank" href="http://www.ukconversionfactorscarbonsmart.co.uk">here</a>.</p>

      <h3>Flight</h3>
      <p>CO<span className='co2sub'>2</span> = d x sos x cor x rf x cf<br/>
      d = distance in km<br/>
      sos = 1.09 (factor for sub-optimal routing and stacking)<br/>
      cor = 1.1 (CORINAIR uplift for descent/takeoff)<br/>
      rf = 1.9 (factor for radiative forcing, NOX, water vapour)<br/>
      cf = conversion factor for selected cabin class and distance band.<br/>
      Please see DEFRA document for further details.</p>

      <h3>Car</h3>
      <p>CO<span className='co2sub'>2</span> = d x cf<br/>
      For simplicity the DEFRA conversion factor (cf) for small and medium vehicles has been combined, leaving normal and large categories.  Petrol and Diesel factors have also be combined proportionally based on percentage of diesel vehicles in use in the UK.
      We have included the option to choose the number of passengers as sharing significantly affects the results for this mode of transport (dividing my 2, 4 etc) whereas the number of passengers for other modes of transport is harder to measure and generally beyond your control.</p>

      <h3>Bus/Coach</h3>
      <p>CO<span className='co2sub'>2</span> = d x cf<br/>
      DEFRA conversion factors (cf) for average bus travel and coach travel are used.</p>

      <h3>Ferry</h3>
      <p>CO<span className='co2sub'>2</span> = d x cf<br/>
      DEFRA conversion factors (cf) for foot and car passengers are used as these vary significantly.</p>

      <h3>Train</h3>
      <p>CO<span className='co2sub'>2</span> = d x cf<br/>
      DEFRA conversion factors (cf) for national rail and Eurostar are provided.</p>

      <p>For more details, please see the DEFRA document linked above or <a target="_blank" rel="noopener noreferrer" href="mailto:info@theconvergingworld.org">contact us</a>.</p>
    </>

}