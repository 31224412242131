import React from 'react';

export default function Faq() {
    return <div>
        <h5>Q: How do you account for the fact that your calculator gives higher/lower CO<sub>2</sub> quantities than others?</h5>

        <p>A: Other calculators are likely to use different methodologies for calculating CO<sub>2</sub>. These are often simplified and do not include all relevant factors. We have used DEFRA-published figures and tried to be as thorough as possible without making the user-experience too complex.</p>

        <h5>Q: What is the suggested donation for a tonne of CO<sub>2</sub>?</h5>

        <p>A: For retiring carbon credits, we recommend a donation of £15. This will support the work of the charity, (including tree planting and eco-restoration) as well as cover the cost of accreditation.</p>
        <p>For tree planting, we average the cost per tree planted (including where applicable maintenance, eco-restoration and community development activities) across our  <a href="https://theconvergingworld.org/eco-restoration/">three partners</a>.</p>

        <h5>Q: Who/What is The Converging World (TCW)?</h5>

        <p>A: You can read all about us in the relevant tab of this about box. Our website contains further details.</p>

        <h5>Q: How will you spend my donation?</h5>

        <p>A: If you choose to donate to retire carbon credits to balance your emissions your funds will be used to remove the(se) credits from the market. The remaining funds will be used to support The Converging World’s charitable work on ecological restoration and poverty alleviation.</p>
        <p>If you donate to balance your footprint through tree planting your donation will be used for tree planting through our partners including wider eco-restoration efforts on the ground in India. See our <a href="https://theconvergingworld.org/eco-restoration/">partner page</a> for more information</p>
        <p>If Gift aid is attached to any donation these funds will be used be used for general purpose by the charity to perform its charitable work.</p>

        <h5>Q: What is the difference between Carbon Credits and Reforestation?</h5>

        <p>A: By providing India’s energy market with clean green power, we also generate carbon credits; CDM (Clean Development Mechanisms)/ CER’s (Certified Emission Reductions) which are accredited by the UNFCCC (United Nations Framework Convention on Climate Change) and have been both validated and verified to the UN standard. This means that every Carbon Credit retired is certified carbon reduction, however each carbon credit represents a tonne of CO2 avoided not removed from the atmosphere.</p>
        <p>Ecological Restoration however is one of the most effective solutions for removing CO2 from the atmosphere. Trees planted in the tropics grow faster and sequester more CO2 when compared to trees growing in temperate climates for example. The carbon figures for tree planting are not certified however, they based on used on scientific papers that estimate it will take 100 years for these absorption rates to be achieved.</p>
    </div>
}





