import * as numeral from "numeral";

export const transportTypes = [
  { key: '0',   value: "select..."},
  { key: 'fle', value: "Flight (Economy)"},
  { key: 'flp', value: "Flight (Premium Economy)"},
  { key: 'flb', value: "Flight (Business)"},
  { key: 'flf', value: "Flight (First)"},
  { key: 'bus', value: "Bus"},
  { key: 'coa', value: "Coach"},
  { key: 'fef', value: "Ferry (Foot)"},
  { key: 'fec', value: "Ferry (Car)"},
  { key: 'trn', value: "Train (National)"},
  { key: 'tre', value: "Train (Eurostar)"},
  { key: 'can', value: "Car (normal)"},
  { key: 'cal', value: "Car (large)"},
]

export const modesAllowingPassengers = ['can', 'cal'];

export const co2Regions = [
  { key: 'uk', value: 'UK average'},
  { key: 'sw', value: 'South west'},
  { key: 'se', value: 'South east'},
  { key: 'nw', value: 'North west'},
  { key: 'ne', value: 'North east'},
]


// great circle distaonce
function greatCircle(lat1,lon1,lat2,lon2) {
    var R = 6371; // km
    lat1 = degRad(lat1);
    lon1 = degRad(lon1);
    lat2 = degRad(lat2);
    lon2 = degRad(lon2);            
    var d = Math.acos(Math.sin(lat1)*Math.sin(lat2) + 
        Math.cos(lat1)*Math.cos(lat2) *
        Math.cos(lon2-lon1)) * R; 
    
    return d;
}


//degress to radians
function degRad($value) {
    return ($value/360) * Math.PI * 2;  
}


export function calculateJourney(originLat, originLong, destLat, destLong, mode, isReturn, passengers, config) {
    
    if (originLat !== "" && originLong !== "" &&  destLat !== "" &&  destLong !== "" && mode !== "0") {
        
        let dist = greatCircle(originLat, originLong, destLat, destLong);
        
        if (isReturn) {
            dist = dist * 2;
        }

        let factor = 0.5
                
        switch(mode)
        {
        case "fle":
          if (dist <= config.flightDistances.domestic) {
            factor = config.flightVariables.sos * config.flightVariables.cor * config.flightConversionFactors.economy.domestic * config.flightVariables.rf;} //domestic
          else if (dist <= config.flightDistances.shortHaul) {
            factor = config.flightVariables.sos * config.flightVariables.cor * config.flightConversionFactors.economy.shortHaul * config.flightVariables.rf;} //short haul
          else {
            factor = config.flightVariables.sos * config.flightVariables.cor * config.flightConversionFactors.economy.longHaul * config.flightVariables.rf;} //long haul
          break;
        case "flp":
          if (dist <= config.flightDistances.domestic) {
            factor = config.flightVariables.sos * config.flightVariables.cor * config.flightConversionFactors.premiumEconomy.domestic * config.flightVariables.rf;} //domestic
          else if (dist <= config.flightDistances.shortHaul) {
            factor = config.flightVariables.sos * config.flightVariables.cor * config.flightConversionFactors.premiumEconomy.shortHaul * config.flightVariables.rf;} //short haul
          else {
            factor = config.flightVariables.sos * config.flightVariables.cor * config.flightConversionFactors.premiumEconomy.longHaul * config.flightVariables.rf;} //long haul
          break;  
        case "flb":
          if (dist <= config.flightDistances.domestic) {
            factor = config.flightVariables.sos * config.flightVariables.cor * config.flightConversionFactors.business.domestic * config.flightVariables.rf;} //domestic
          else if (dist <= config.flightDistances.shortHaul) {
            factor = config.flightVariables.sos * config.flightVariables.cor * config.flightConversionFactors.business.shortHaul * config.flightVariables.rf;} //short haul
          else {
            factor = config.flightVariables.sos * config.flightVariables.cor * config.flightConversionFactors.business.longHaul * config.flightVariables.rf;} //long haul
          break;
        case "flf":
          if (dist <= config.flightDistances.domestic) {
            factor = config.flightVariables.sos * config.flightVariables.cor * config.flightConversionFactors.first.domestic * config.flightVariables.rf;} //domestic
          else if (dist <= config.flightDistances.shortHaul) {
            factor = config.flightVariables.sos * config.flightVariables.cor * config.flightConversionFactors.first.shortHaul * config.flightVariables.rf;} //short haul
          else {
            factor = config.flightVariables.sos * config.flightVariables.cor * config.flightConversionFactors.first.longHaul * config.flightVariables.rf;} //long haul
          break;
        case "can":
          factor = config.carConversionFactors.normal;
          break;
        case "cal":
          factor = config.carConversionFactors.normal;
          break;
        case "bus":
          factor = config.busConversionFactor;
          break; 
        case "coa":
          factor = config.coachConversionFactor;
          break;  
        case "fef":
          factor = config.ferryConversionFactors.foot;
          break; 
        case "fec":
          factor = config.ferryConversionFactors.car;
          break; 
        case "trn":
          factor = config.trainConversionFactors.national;
          break; 
        case "tre":
          factor = config.trainConversionFactors.eurostar;
          break; 
        default:
          factor = 0.5;
        }
        const passengerFactor = passengers && modesAllowingPassengers.includes(mode) ? passengers : 1;
        const co2 = (dist * factor) / passengerFactor;
        return [dist, mode ? co2 : null]
        
    }
}

export function displayCO2(n) {
    return n >= 1000 ? numeral(n / 1000).format('0.0') + ' tonnes' : numeral(n).format('0') + ' kg';
}