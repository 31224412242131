import React, { useState } from 'react';
import { ToggleButtonGroup, ToggleButton, Form, Row, Col, InputGroup } from 'react-bootstrap';
import { co2Regions } from '../calculations'

export default function AnnualCO2({updateAnnualCO2, customAmount, getAnnualCO2}) {
    const [type, setType] = useState(null);

    const updateType = val => {
        setType(val);
        updateAnnualCO2('type')(val);
    }
    return <Row>
        <Col md={6}>
            <ToggleButtonGroup onChange={updateType} name="annual_co2" aria-label="Annual CO2" defaultValue="none">
                <ToggleButton value="none" variant="primary">No thanks</ToggleButton>
                <ToggleButton value="average" variant="primary">Use a UK average</ToggleButton>
                <ToggleButton value="custom" variant="primary">I know my CO<sub>2</sub></ToggleButton>
            </ToggleButtonGroup>        
        </Col>
        <Col md={6}>
            {
                type && type !== 'none' ?
                    type === 'average' ?
                    <div>
                        <Form.Control
                            as="select"
                            onChange={e => {
                                updateAnnualCO2('region')(e.target.value)
                            }}
                        >
                            <option>choose a region</option>
                            {co2Regions.map(type => <option key={type.key} value={type.key}>{type.value}</option>)}

                        </Form.Control>
                    </div> :
                    <div>
                        <InputGroup>
                          <Form.Control type='text' placeholder='Type your annual co2' value={customAmount} onChange={e => updateAnnualCO2('customAmount')(parseInt(e.target.value))} />
                          <InputGroup.Append>
                            <InputGroup.Text id="inputGroupAppend">kg</InputGroup.Text>
                          </InputGroup.Append>
                        </InputGroup>
                    </div>
                    :
                    null
            }        
        </Col>        
    </Row>
}