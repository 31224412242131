import React from 'react';
import { Row, Col } from 'react-bootstrap';

export default function ColumnHeadings() {
    return <Row className="column-headings">
        <Col className="col-delete">
        </Col>
        <Col className="col-origin">
            Origin
        </Col>
        <Col className="col-destination">
            Destination
        </Col>
        <Col className="col-return">
            Return
        </Col>
        <Col className="col-mode">
            Mode
        </Col>
        <Col className="col-passengers">
            Passengers
        </Col>
        <Col className="col-distance">
            Distance
        </Col>
        <Col className="col-co2">
            CO<sub>2</sub>
        </Col>
    </Row>
}