import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
const numeral = require('numeral');

export default function donationOptions({config, getTotalCO2}) {
    const totalCO2 = getTotalCO2()
    let [costOfCarbon, costOfTrees] = [0, 0];
    let [treesOnce, treesMonthly, carbonOnce, carbonMonthly] = ["", "", "", ""];
    const donateParam = "?donate_amount=" 
    if (config) {
      costOfCarbon = config.costOfCarbon
      costOfTrees = config.costOfTrees
      treesOnce = config.donatePages.treesOnce
      treesMonthly = config.donatePages.treesMonthly
      carbonOnce = config.donatePages.carbonOnce
      carbonMonthly = config.donatePages.carbonMonthly
    }
    return <div className="donation-options">
      <Row>
        <Col xs={6}>

        </Col>
        <Col xs={3}>
          One off
        </Col>
        <Col xs={3}>
          Monthly
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
            Donation into Trees
        </Col>
        <Col xs={3}>
          <Button href={treesOnce + donateParam + numeral(totalCO2 * costOfTrees).format('0.00')}>£ {numeral(totalCO2 * costOfTrees).format('0.00')}</Button>  
        </Col>  
        <Col xs={3}>
          <Button href={treesMonthly + donateParam + numeral(totalCO2 * costOfTrees/12).format('0.00')}>£ {numeral(totalCO2 * costOfTrees/12).format('0.00')}</Button>
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          Donations into Carbon Credits
        </Col>
        <Col xs={3}>
        <Button href={carbonOnce + donateParam + numeral(totalCO2 * costOfCarbon).format('0.00')}>£ {numeral(totalCO2 * costOfCarbon).format('0.00')}</Button>
        </Col>
        <Col xs={3}>
        <Button href={carbonMonthly + donateParam + numeral(totalCO2 * costOfCarbon/12).format('0.00')}>£ {numeral(totalCO2 * costOfCarbon/12).format('0.00')}</Button>
        </Col>
      </Row>
    </div>
}