import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { displayCO2 } from '../calculations';
const numeral = require('numeral');

export default function({show, handleClose, getTotalCO2, config}) {
    const yourCo2 = getTotalCO2();
    const ukCo2 = config ? config.annualCo2.uk * 1000 : 0
    let yourCo2Width = 100;
    let ukCo2Width = 100;
    if (yourCo2 < ukCo2) {
        yourCo2Width = (yourCo2/ukCo2) * 100;
    } else {
        ukCo2Width = (ukCo2/yourCo2) * 100;
    }
    const {kettle, km} = config ? config.co2Equivalents : {kettle: 0, km: 0};

    return <Modal className="explain-modal" show={show} onHide={handleClose}>
    <Modal.Header closeButton>
      <Modal.Title>About your CO<sub>2</sub></Modal.Title>
    </Modal.Header>
    <Modal.Body>
        <h5>Emissions comparison</h5>
        <p>This graph puts the total emissions of your travel (in tonnes of CO<sub>2</sub>) in context.</p>
        <div className="emissions-comparison">
            <div style={{width: yourCo2Width + "%"}} className="your-co2">
                {displayCO2(yourCo2)}
            </div>
            <p>Your CO<sub>2</sub></p>
            <div style={{width: ukCo2Width + "%"}} className="uk-co2">
                {displayCO2(ukCo2)}
            </div>
            <p>UK Annual emissions per capita</p>
        </div>
        <p>This table shows your emissions and the UK average annual emissions in different units.</p>
        <table className="table striped">
          <thead>
            <tr>
              <th></th>
              <th>You</th>
              <th>UK Annual emissions per capita</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>CO<sub>2</sub> (tonnes)</td>
              <td>{displayCO2(yourCo2)}</td>
              <td>{displayCO2(ukCo2)}</td>
            </tr>
            <tr>
              <td>Equivalent number of kettles boiled</td>
              <td>{numeral(yourCo2 * kettle).format(0,0)}</td>
              <td>{numeral(ukCo2 * kettle).format(0,0)}</td>
            </tr>
            <tr>
              <td>Equivalent km of driving car</td>
              <td>{numeral(yourCo2 * km).format(0,0)}</td>
              <td>{numeral(ukCo2 * km).format(0,0)}</td>
            </tr>
          </tbody>
        </table>
        <h5>What does this mean?</h5>
        <p>UK emissions per capita - gives the total annual CO<sub>2</sub> emitted by a person in the UK including travel, home energy, food etc. Source: World Bank</p>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={handleClose}>
        Close
      </Button>
    </Modal.Footer>
  </Modal>
}