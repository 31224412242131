import React from 'react';
import { Button, Tabs, Tab, Container, Row, Col } from 'react-bootstrap';
import Journey from './components/Journey';
import AnnualCO2 from './components/AnnualCO2';
import DonationOptions from './components/DonationOptions';
import Instructions from './components/Instructions';
import About from './components/About';
import { calculateJourney, displayCO2 } from './calculations';
import './scss/styles.scss';
import ColumnHeadings from './components/ColumnHeadings';
import ExplainModal from './components/ExplainModal';
import logo from './images/logo.png';
import banner from './images/banner.png';
import footerAddress from './images/footer-address.png';

class App extends React.Component {

  constructor() {
    super();

    this.state = {
      journeys: [],
      annual: {
        type: null,
        customAmount: null,
        region: null,
      },
      config: null,
      explaining: false,
    }
  }

  componentDidMount() {
    this.getConfig()
    this.addJourney()
  }

  getConfig() {
    fetch('https://calculator.theconvergingworld.org/config_JSON.php', {
      method: 'get'
    })
    .then( response => response.json())
    .then(config => {
      this.setState({config})
    })
    .catch(function(err) {
      // Error :(
    });
  }

  addJourney(followOnOrigin = null) {
    this.setState({
      journeys: [...this.state.journeys, {
        type: null,
        passengers: null,
        origin: followOnOrigin || {
            label: null,
            lat: null,
            long: null,
        },
        destination: {
          label: null,
          lat: null,
          long: null,
        },
        isReturn: false,
      }]
    })
  }

  getLastDestination() {
    const lastJourney = this.state.journeys.slice(-1)[0];
    const lastDestination = lastJourney ? lastJourney.destination : null;
    return lastDestination && lastDestination.lat ? lastDestination : null;
  }

  addFollowOnJourney() {
    this.addJourney(this.getLastDestination());
  }

  resetJourneys() {
    this.setState({
        journeys: []
    }, this.addJourney);
  }

  updateJourneyField(index) {
    return key => {
      return value => {
        const journeys = this.state.journeys.map((j, i) => {
          return i === index ? {...j, [key]: value} : j;
        })
        this.setState({journeys});
      }
    }
  }

  removeJourney(index) {
    this.setState({
        journeys: this.state.journeys.filter((_, i) => index !== i)
    }, () => {
      if (this.state.journeys.length === 0) {
        this.addJourney();
      }
    })
  }

  updateAnnualCO2(key) {
    return value => {
      this.setState({annual: {...this.state.annual, [key]: value}})
    }
  }

  getAnnualCO2() {
    const type = this.state.annual.type;
    if (type === 'none') return 0;
    const useAverage = type === 'average' && this.state.annual.region;
    return useAverage ? (this.state.config.annualCo2[this.state.annual.region] * 1000) : this.state.annual.customAmount || 0;
  }

  getTotalCO2() {
    return this.getAnnualCO2() + this.getTotalJourneyCO2();
  }

  getJourneyCO2(journey) {
    const { origin, destination, type, isReturn, passengers } = journey;
    if (typeof origin === 'undefined' || origin.lat === null || destination.lat === null) {
      return [null, null];
    }
    return calculateJourney(origin.lat, origin.long, destination.lat, destination.long, type, isReturn, passengers, this.state.config); 
  }

  getTotalJourneyCO2() {
    return this.state.journeys.reduce((acc, journey) => {
        const journeyCO2 = this.getJourneyCO2(journey);
        return acc + (journeyCO2 ? journeyCO2[1] : 0);
    }, 0);
  }

  explain() {
    this.setState({explaining: true})
  }

  stopExplaining() {
    this.setState({explaining: false})
  }

  render() {
    return <Container>
    <Row className="header">
      <Col md={5} xs={6} className="banner">
        <img src={banner} alt="The Converging World Banner" />
      </Col>
      <Col md={7} xs={6} className="text-right logo">
        <img src={logo} alt="The Converging World Logo"/>
      </Col>
    </Row>

    <Row><Col xs={12}>
      <Tabs defaultActiveKey="calculator" id="uncontrolled-tab-example" className="main-tabs">
        <Tab eventKey="calculator" title="Calculator">
          <Row>
            <Col>
              <h5 className="section-title">Calculate my travel</h5>
            </Col>
            <Col className="section-title text-right">
              <h5>{displayCO2(this.getTotalJourneyCO2())}</h5>
            </Col>
          </Row>
          <ColumnHeadings/>
          {
            this.state.journeys.map((journey, index) => <Journey
              journey={journey}
              index={index}
              key={index}
              updateJourneyField={this.updateJourneyField.bind(this)(index)}
              calculateCo2={this.getJourneyCO2.bind(this)}
              removeJourney={_ => this.removeJourney.bind(this)(index)}
            />
            )
          }
          <Row>
            <Col md={8}>
              <Button onClick={_ => this.addJourney.bind(this)()}>Add a journey</Button>
              {
                this.getLastDestination() &&
                <Button onClick={this.addFollowOnJourney.bind(this)}>Follow on journey</Button>
              }
              <Button onClick={this.resetJourneys.bind(this)}>Reset</Button>
            </Col>
          </Row>

          <hr/>
          <Row>
            <Col>
              <h5 className="section-title">Add my annual emissions</h5>            
            </Col>
            <Col className="section-title text-right">
              <h5>{displayCO2(this.getAnnualCO2())}</h5>
            </Col>
          </Row>
          <AnnualCO2 updateAnnualCO2={this.updateAnnualCO2.bind(this)} customAmount={this.state.annual.customAmount || ''} getAnnualCO2={this.getAnnualCO2.bind(this)} />
          
          <hr/>

          <Row className="totals">
            <Col md={2}>
              <Button onClick={this.explain.bind(this)}>Explain</Button>
            </Col>
            <Col md={{ span: 2, offset: 6 }} className="section-title text-right">
              <h5>Total CO<sub>2</sub></h5>
            </Col>
            <Col md={{ span: 2, offset: 0 }} className="section-title text-right">
              <h5>{displayCO2(this.getTotalCO2())}</h5>
            </Col>
          </Row>
          
          <hr/>

          <Row>
            <Col>
              <h5 className="section-title">Suggested donation</h5>
              <p>
                  You can choose to donate to absorb your carbon through <a href="https://theconvergingworld.org/eco-restoration/">tree planting </a> or to balance your carbon through retiring UN approved <a href="https://theconvergingworld.org/carbon-credits">carbon credits</a>
              </p>
            </Col>
          </Row>

          <DonationOptions getTotalCO2={this.getTotalCO2.bind(this)} config={this.state.config}/>          
        </Tab>
        <Tab eventKey="instructions" title="Instructions">
          <Instructions/>
        </Tab>
        <Tab eventKey="about" title="About">
          <About/>
        </Tab>
      </Tabs>
      </Col></Row>
      <Row className="footer">
        <Col xs={4}>
        </Col>
        <Col xs={4}>
          <a target="_blank" rel="noopener noreferrer" href="https://theconvergingworld.org/">
            <img src={footerAddress} alt="The Converging World Website"/>
          </a>
        </Col>
        <Col xs={4}>
        </Col>
      </Row>
      <ExplainModal show={this.state.explaining} handleClose={this.stopExplaining.bind(this)} getTotalCO2={this.getTotalCO2.bind(this)} config={this.state.config}/>
      </Container>
  }

}

export default App