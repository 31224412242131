import React from 'react';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import {asyncContainer, Typeahead} from 'react-bootstrap-typeahead';
const AsyncTypeahead = asyncContainer(Typeahead);

export default class PlaceAutocomplete extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            options: [],
        }
    }

    render() {
        return <AsyncTypeahead
            align="left"
            id={this.props.id}
            isLoading={this.state.isLoading}
            onSearch={query => {
                this.setState({isLoading: true});
                fetch(`https://calculator.theconvergingworld.org/data_JSON.php?term=${query}`)
                .then(resp => resp.json())
                .then(json => this.setState({
                    isLoading: false,
                    options: json.cities.map(place => ({
                        label: place.key,
                        id: place.id,
                        lat: place.lat,
                        long: place.long,
                    })),
                }));
            }}
            options={this.state.options}
            onChange={([place]) => {
                this.props.onChange(place)
            }}
            selected={this.props.selected}
            minLength={3}
            emptyLabel='Some villages may not be listed - please search google maps for your local town'
        />
    }

}