import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import Calculations from './about/Calculations';
import About2 from './about/About2';
import Faq from './about/Faq';
import Projects from './about/Projects';

export default function About() {
    return <Tabs defaultActiveKey="about">
      <Tab eventKey="about" title="About">
        <About2/>
      </Tab>
      <Tab eventKey="calculations" title="Calculations">
        <Calculations/>
      </Tab>
      {/*<Tab eventKey="donations" title="Donations">*/}
        {/*<Donations/>*/}
      {/*</Tab>*/}
      <Tab eventKey="faq" title="FAQs">
        <Faq/>
      </Tab>
      <Tab eventKey="projects" title="Projects">
        <Projects/>
      </Tab>
      {/*<Tab eventKey="tcw" title="TCW">*/}
        {/*<Tcw/>*/}
      {/*</Tab>*/}
  </Tabs>
}