import React from 'react';

export default function Instructions() {
    return <div>
        <p>The TCW Carbon Calculator allows you to calculate the greenhouse gas emissions of your travel. You can input multiple journeys using a variety of modes of transport.</p>

        <p>To begin using the calculator:</p>
        <ul> 
            <li>Start typing the origin city of your journey into the first box.</li>
            <li>As you type, a list of world cities will appear. From this list you can choose the correct city.</li>
            <li>Select the mode of transport you will be using and tick the box if you are making a return journey.  If you choose a journey via car you can additionally select the number of passengers so the carbon emitted is shared amongst them.</li>
            <li>To add a follow-on journey from your destination click the 'Follow-on' button or to add an additional separate journey click the 'New' button.</li>
            <li>Once you have filled in your journey's details, the distance and CO<sub>2</sub> emissions will then be calculated automatically.</li>
            <li>If you want to change the start, destination or mode of transport, hover over it until you see the cross appear then click to show the selection box again.</li>
            <li>To begin a new set of journeys click 'Reset' to clear your last journey's details.</li>
        </ul>	

        <p>Once you have added all of your journeys you can click 'explain' to get more details about the CO<sub>2</sub> emissions of your travel.</p>

        <p>You can choose to donate to The Converging World in acknowledgement of these emissions. You can now choose how you want to balance your carbon footprint. You can choose to either absorb CO<sub>2</sub> through planting trees with our partners in India, or offset your carbon footprint through retiring UN accredited carbon credits generated from our wind farms.</p>
    </div>
}